import React from 'react';
import SocialMedia from './SocialMedia';
import './Footer.css';

function Footer() {
    return (
        <>
            <SocialMedia />
            
        </>
    )
}

export default Footer;