import { useState } from "react";
import * as emailjs from "emailjs-com";
import "./ContactForm.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
// import { loadReCaptcha } from "react-recaptcha-google";

function ContactForm() {
    const [name, setName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [message, setMessage] = useState("");
    const [buttonText, setButtonText] = useState("Send");

    const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;
    const userID = process.env.REACT_APP_USER_ID;
    const recaptchaKey = process.env.REACT_APP_RECAPTUR_KEY;

    // function onChange(value) {
    //     console.log("Captcha value:", value);
    // }

    function resetForm() {
        setName("");
        setCompanyName("");
        setEmail("");
        setPhone("");
        setMessage("");
    }

    function formSubmit(e) {
        e.preventDefault();
        setButtonText("Sending...");
        const templateParams = {
            from_name: name,
            from_company_name: companyName,
            from_email: email,
            from_phone: phone,
            to_name: "Alan",
            message_html: message
        };
        emailjs.send(
            serviceID,
            templateID,
            templateParams,
            userID,
        )
        .then((result) => {
            console.log(result.text);
            alert(`Thank you for getting in touch. ${templateParams.to_name} will be in touch soon.`);
            setButtonText("Message Sent");
            resetForm();
        }, (error) => {
            console.log(error.text);
        });
        
    }

    // successAlert() {
    //     return (
    //         <>
    //             <Alert variant="success">
    //                 <Alert.Heading>Message Sent</Alert.Heading>
    //                 <p>
    //                 `Thank you for getting in touch. ${templateParams.to_name} will be in touch soon.`
    //                 </p>
    //             </Alert>
    //         </>
    //     )
    // }

    return (
        <div>
        <div className="contact-container">
            
            <Container fluid>
                <Row>
                    <Col>
                        <Form onSubmit={(e) => formSubmit(e)}>
                        <h1>Get in touch</h1>
                            <Form.Group as={Row} controlId="formGridName">
                                <Form.Label column sm="2">
                                    Name
                                </Form.Label>
                                <Col sm="10">
                                    <Form.Control 
                                        type="text"
                                        placeholder="Your name"
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        name="name"
                                        value={name}
                                        required
                                        
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formGridCompanyName">
                                <Form.Label column sm="2">
                                    Company Name
                                </Form.Label>
                                <Col>
                                    <Form.Control 
                                        type="text"
                                        placeholder="Your company name"
                                        onChange={(e) => {
                                            setCompanyName(e.target.value);
                                        }}
                                        name="companyName"
                                        value={companyName}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formGridEmail">
                                <Form.Label column sm="2">
                                    Email
                                </Form.Label>
                                <Col>
                                    <Form.Control 
                                        type="email"
                                        placeholder="your@email.com"
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        name="email"
                                        value={email}
                                        required
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="formGridPhone">
                                <Form.Label column sm="2">
                                    Phone
                                </Form.Label>
                                <Col>
                                    <Form.Control 
                                        type="text"
                                        placeholder="Your phone no."
                                        onChange={(e) => {
                                            setPhone(e.target.value);
                                        }}
                                        name="phone"
                                        value={phone}
                                    />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} controlId="exampleForm.ControlTextarea">
                                <Form.Label column sm="2">
                                    Your Message
                                </Form.Label>
                                <Col className="form-col">
                                    <Form.Control 
                                        as="textarea"
                                        placeholder="Enter your message"
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                        name="message"
                                        value={message}
                                        required
                                        rows={5}
                                    />
                                </Col>
                            </Form.Group>
                            

                            <Row className="form-btn-row">
                                
                            
                                <Button variant="primary" type="submit">
                                    {buttonText}
                                </Button>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
    )
}

export default ContactForm;

// <div class="g-recaptcha" data-sitekey={recaptchaKey}></div>

// <ReCAPTCHA
// sitekey={recaptchaKey}
// onChange={onChange}
// />