import React from 'react';
import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';

function NavButton({ setPage, setOpen }) {

  const linkButtons = [
    {
      "#": 1,
      "ref": "intro-page",
      "buttonName": "Home",
      "route": "/",
      "show": true
    },
    {
      "#": 2,
      "ref": "about-page",
      "buttonName": "About",
      "route": "about",
      "show": true
    },
    {
      "#": 3,
      "ref": "projects-page",
      "buttonName": "Projects",
      "route": "projects",
      "show": false
    },
    {
      "#": 4,
      "ref": "blog-page",
      "buttonName": "Blog",
      "route": "blog",
      "show": false
    },
    {
      "#": 5,
      "ref": "Home-Automation-page",
      "buttonName": "Home Automation",
      "route": "homeautomation",
      "show": false
    },
    {
      "#": 6,
      "ref": "contact-page",
      "buttonName": "Get in Touch",
      "route": "contact",
      "show": true
    }
  ]
  
  const navLinkButton = linkButtons.map((navLink, index) => {
      if ( navLink.show === true ) {
      return (
        <NavLink to={navLink.route} className='nav-button' onClick={() => setOpen(false)} >
          <li key={index} className="nav-button">
            {navLink.buttonName}
          </li>
        </NavLink>
      );
      } else {
        return null;
      }
    });
  
  return (
    <Nav>
      {navLinkButton}
    </Nav>
  )
}

export default NavButton;