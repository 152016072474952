import React from 'react';
import ContactForm from "../components/ContactForm/ContactForm";

function ContactPage() {

    return (
        <div>
            <ContactForm />
        </div>
    )
}

export default ContactPage;

