import React from 'react';
import Media from 'react-bootstrap/Media';
import Container from "react-bootstrap/Container";
import Image from 'react-bootstrap/Image';
import '../components/AboutPage/AboutPage.css';

function AboutPage() {

    return (
        <div>
        <div className="about-container">
            <Container fluid>
            
                <Media>
                    <Image
                        width={164}
                        thumbnail
                        className="mr-3"
                        src="/assets/me.jpg"
                        alt="Me"
                    />
                    <Media.Body>
                        <h1>A little bit about who I am...</h1>
                        <p>
                        I am a Junior software developer with a background in Sales, 
                        Logistics and IT Support. In 2020 I decided to return to the 
                        tech industry and attended CodeClan in Edinburgh to study 
                        Profesional Software Development. Since then, I have been 
                        volunteering with the Scottish Tech Army and have started 
                        studying part-time Software Development at Glasgow Caledonian 
                        University.
                        <br/><br/>
                        I have an interest in Home Automation, design and 3D printing. 
                        I hope to soon showcase projects I have produced and collaborated 
                        on along with blog posts on Home Automation, 3D printing and my 
                        journey to becoming a software developer.
                        <br/><br/>
                        Please feel free to check out my LinkedIn, and thank you for 
                        visiting my site.
                        </p>
                        <div className="about-images-container">
                            <img src="assets/cclogo.svg" alt="CodeClan" />
                            <img src="assets/stalogo.svg" alt="STA" />
                            <img src="assets/halogo.svg" alt="Home Assistant" />
                        </div>
                    </Media.Body>
                </Media>
            
            </Container> 
        </div>
        </div>
    )
}

export default AboutPage;