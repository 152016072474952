import React from 'react';
import styled from 'styled-components';
import NavButton from "./NavButton";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  li {
    padding: 25px 15px;
  }
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    border-left: solid 3px #ffa500;
    border-bottom: solid 3px #ffa500;
    background-color: #fcfcfc;
    position: fixed;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    top: 0;
    right: 0;
    height: 80vh;
    width: 300px;
    padding-top: 3.5rem;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    li {
      color: #555555;
      width: 300px;
    }
  }
`;

const RightNav = ({ open, setOpen }) => {

  return (
    <Ul open={open}>
      <NavButton setOpen={setOpen} />
    </Ul>
  )
}

export default RightNav;