import React from 'react';
import Particles from 'react-tsparticles';
import ParticlesData from '../Particles.json';
import { NavLink } from "react-router-dom";
// import Nav from 'react-bootstrap/Nav'

function IntroPage() {

    const particleData = ParticlesData;

    return (
        <div>
            <Particles className="particles"
                params={particleData}
            />
            <div className="intro-page-content">
                <div className="intro-logo">
                    <img src="/assets/logo-ar-text.svg" alt="" />
                    <h1 className="intro-logo-text">Alan Ramsay</h1>
                    <p className="strapline">Software Developer</p>
                </div>
                <div className="link-to-page">
                    <NavLink to="/about" className='nav-link'>
                        <p>Click to discover more!</p>
                    </NavLink>
                </div>
            </div>
            
        </div>
    )
}

export default IntroPage;