import './App.css';
import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
// import ReactDOM from 'react-dom';
// import { Router, Route, Switch, IndexRoute, Link, IndexLink, browserHistory, hashHistory } from 'react-router';
import NavBar from "./components/Nav/NavBar";
import IntroPage from "./pages/IntroPage";
import AboutPage from "./pages/AboutPage";
// import ProjectsPage from "./pages/ProjectsPage";
// import BlogPage from "./pages/BlogPage";
// import OnePost from './components/sanity/OnePost';
// import HAPage from "./pages/HAPage";
// import PrintingPage from "./pages/PrintingPage";
import ContactPage from "./pages/ContactPage";
// import SocialMedia from "./components/SocialMedia";
import Footer from './components/Footer/Footer';

function App() {

  const [open, setOpen] = useState(false);
  // const [showFooter, setShowFooter] = useState(true);
  
  return (
    <div className="App">
      <NavBar open={open} setOpen={setOpen} />
      <main className="main-page">
        <Switch>
          <Route path="/" component={IntroPage} exact className="switched-page" />
          <Route path="/about" component={AboutPage} />

          <Route path="/contact" component={ContactPage} />
        </Switch>
      </main>
      <footer>
        <Footer  />
        <p className="copyright-text">&#9400; 2020 Alan Ramsay</p>
      </footer>
    </div>
  );
}

export default App;

// <Route path="/projects" component={ProjectsPage} />
// <Route path="/blog" component={BlogPage} />
// <Route path="/:slug" component={OnePost} />
// <Route path="/homeautomation" component={HAPage} />
// <Route path="/3dprinting" component={PrintingPage} />

// <SocialMedia />
//      <p className="copyright-text">&#9400; 2020 Alan Ramsay</p>