import React from 'react';
import Burger from './Burger';
import styled from 'styled-components';

const Nav = styled.nav`
  width: 100%;
  height: 75px;
  border-bottom: 5px solid #ffa500;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  .logo {
    padding: 25px 0;
  }
  .logo-text {
    padding: 0 80px;
  }
`

const Navbar = ({ open, setOpen }) => {
  return (
    <Nav className="header">
      <div className="logo">
        <img src="/assets/logo-ar.svg" alt="" />
        <h1 className="logo-text">Alan Ramsay</h1>
        {/* <p className="strapline">Software Development</p> */}
      </div>
      <Burger open={open} setOpen={setOpen} />
    </Nav>
  )
}

export default Navbar;