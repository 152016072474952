import React from 'react';

function SocialMedia(params) {

    return (
        <div className="social">
            <div className="social-container" >
                <a href="https://github.com/aramsay03" target="_blank"><div className="social-link" ><img src="assets/GitHub120px.png" alt="GitHubLogo" /></div></a>
                <a href="https://www.linkedin.com/in/alan-ramsay/" target="_blank"><div className="social-link" ><img src="assets/LinkedIn.png" alt="LinkedInLogo" /></div></a>
                <a href="https://www.instagram.com/aramsay03" target="_blank"><div className="social-link" ><img src="assets/Instagram.png" alt="InstagramLogo" /></div></a>
            </div>
        </div>
    )
}

export default SocialMedia;